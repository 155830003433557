<template>
  <div
    id="login"
    style="min-height: calc(100vh - 20px)"
    class="fill-height d-flex align-items-center justify-content-center"
    tag="section"
  >
    <b-row class="justify-content-center no-gutters">
      <b-col class="d-flex align-items-center">
        <b-card no-body>
          <b-row class="no-gutters">
            <b-col
              class="bg-primary d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                    <h2 class="display-5 text-white fw-medium px-4">
                      MD Logistics &amp; Hawk Dist<br>Infomatic Console
                    </h2>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="p-4">
                <img src="@/assets/images/logo-icon.png" />
                <h2 class="font-weight-bold mt-4">Sign in</h2>
                <!--<h6 class="mb-4">
                  Don't have an account?
                  <b-link to="/authentication/boxedregister" class
                    >Sign Up</b-link
                  >
                </h6>-->

                
                  <b-form-group>
                    <b-form-input
                      id="txt-username"
                      type="text"
                      placeholder="Enter Username"
                      class="mb-3"
                      v-model="$v.form.username.$model"
                      :state="validateState('username')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-username"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-form-group>
                    <b-form-input
                      id="txt-pwd"
                      type="password"
                      placeholder="Enter Password"
                      class="mb-3"
                      v-model="$v.form.pwd.$model"
                      :state="validateState('pwd')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="txt-pwd"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-form-group>

                  <!--<div class="d-flex align-items-center">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="checkbox"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      I accept the terms and use
                    </b-form-checkbox>

                    <div class="ml-auto">
                      <a href="javascript:void(0)" class="link">Forgot pwd?</a>
                    </div>
                  </div>-->
                  <b-button
                    variant="primary"
                    :disabled="$v.form.$invalid"
                    size="lg"
                    block
                    class="mt-4"
                    @click="login()"
                    >Sign In</b-button
                  >
                
                <!--<div
                  class="d-flex align-items-center justify-content-center mt-4"
                >
                  <b-badge
                    href="#"
                    pill
                    variant="info"
                    class="mr-2 px-3 py-2 d-flex align-items-center"
                  >
                    <feather type="twitter" class="feather-sm mr-2"></feather>
                    Twitter
                  </b-badge>
                  <b-badge
                    href="#"
                    pill
                    variant="dark"
                    class="px-3 py-2 d-flex align-items-center"
                  >
                    <feather type="github" class="feather-sm mr-2"></feather
                    >Github
                  </b-badge>
                </div>-->
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from 'vuex';

export default {
  name: "BoxedLogin",
  mixins: [validationMixin],
  data: () => ({
    form: {
      username: "",
      pwd: "",
    },
    checkbox: false,
    users: [
      "basharat.mdlogistics@gmail.com",
      "aakifabbas.aa@gmail.com",
      "saad.mdlogistics@gmail.com",
      "sarwar_irfan@hotmail.com",
      "saqib.mdlogistics@gmail.com",
      "fahad.mdlogistics@gmail.com",
      "umarsaleem.mdlogistics@gmail.com",
      "faisal.hawkpvtltd@gmail.com",
      "binrasheed381@gmail.com"
    ],
    usersInfo: [
      { email: "basharat.mdlogistics@gmail.com", pwd:"bmgc2023!", access: [1,2,3,4] },
      { email: "aakifabbas.aa@gmail.com", pwd:"aagc2023@", access: [1,2,3,4] },
      { email: "saad.mdlogistics@gmail.com", pwd:"smgc2023#", access: [1,2,3,4] },
      { email: "sarwar_irfan@hotmail.com", pwd:"sihm2023$", access: [1,2] },
      { email: "saqib.mdlogistics@gmail.com", pwd:"smgc2023!", access: [3,4] },
      { email: "fahad.mdlogistics@gmail.com", pwd:"fmgc2023@", access: [1] },
      { email: "umarsaleem.mdlogistics@gmail.com", pwd:"umgc2023#", access: [2,4] },
      { email: "faisal.hawkpvtltd@gmail.com", pwd:"fhgc2023$", access: [3] },
      { email: "binrasheed381@gmail.com", pwd:"brgc2023!", access: [2] }
    ]
  }),
  computed: {
      ...mapState({
        faujiCircle: state => state.dashboard.fauji,
      })
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(4),
      },
      pwd: {
        required,
        minLength: minLength(8),
      },
    },
  },
  methods: {
    ...mapActions(["saveAuthData"]),
    validateState(username) {
      const { $dirty, $error } = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    login(){
      if(this.users.includes(this.form.username)){
        if(this.usersInfo[this.users.indexOf(this.form.username)].pwd === this.form.pwd){
          let access = this.usersInfo[this.users.indexOf(this.form.username)].access;
          this.saveAuthData({username: this.form.username, access: access});  
          this.$router.push({path: "/"});
        }
        else{
          // Create the toast
          this.$bvToast.toast('Password is invalid!', {
            title: `Error`,
            variant: 'danger',
            solid: true
          })
        }
      }
      else{
        // Create the toast
          this.$bvToast.toast('Email not registered to use this application!', {
            title: `Error`,
            variant: 'danger',
            solid: true
          })
      }
    }
  },
};
</script>
